export const color = {
  $white: '#ffffff',
  $main500: '#5d3be5',
  $main300: '#ded6ff',
  $main100: '#faf9ff',
  $main: {
    100: '#EFEBFC',
    200: '#DFD8FA',
    300: '#CEC4F7',
    400: '#BEB1F5',
    500: '#AE9DF2',
    600: '#9E89EF',
    700: '#8E76ED',
    800: '#7D62EA',
    900: '#6D4FE8',
  },
  $mono_000_light: '#222',
  $mono_100_light: '#8a8a8e',
  $mono_200_light: '#bfbfc1',
  $mono_300_light: '#dcdcdd',
  $mono_000_dark: '#fff',
  $mono_100_dark: '#f3f3f9',
  $mono_200_dark: '#f9f9fc',
  $mono_300_dark: '#fbfbfd',
  $fill_000_light: '#e9e9eb',
  $fill_100_light: '#efeff0',
  $fill_200_light: '#f4f4f5',
  $fill_300_light: '#f9f9fa',
  $fill_000_dark: '#bbbbbf',
  $fill_100_dark: '#d3d3d6',
  $fill_200_dark: '#dedee0',
  $fill_300_dark: '#e9e9eb',
  $black: '#222',
  $black_100: '#111',
  $black_200: '#222',
  $black_300: '#333',
  $black_400: '#444',
  $black_500: '#555',
  $black_600: '#666',
  $black_700: '#777',
  $black_800: '#888',
  $black_900: '#999',
  $black_aaa: '#aaa',
  $black_bbb: '#bbb',
  $black_ccc: '#ccc',
  $black_ddd: '#ddd',
  $black_eee: '#eee',
};

export const font = {
  $headline_01: '50px',
  $headline_02: '40px',
  $headline_03: '30px',
  $title_01: '28px',
  $title_02: '26px',
  $title_03: '24px',
  $title_04: '22px',
  $body_01: '20px',
  $body_02: '16px',
  $body_03: '16px',
  $body_04: '14px',

  $text_xs: 14,
  $text_sm: 16,
  $text_base: 20,
  $text_lg: 22,
  $text_xl: 24,
  $text_2xl: 26,
  $text_3xl: 28,
  $text_4xl: 30,
  $text_5xl: 40,
  $text_6xl: 50,
  light: 100,
  normal: 400,
  bold: 800,
  $leading_01: 1,
  $leading_02: 1.5,
  $leading_03: 2,
  $leading_04: 2.5,
  $leading_05: 3,
  $leading_06: 3.5,
  $leading_07: 4,
};

export const fontSize = (size, media = '1280px') => {
  switch (media) {
    case '640px': {
      return `${Math.round(size * 0.8)}px`;
    }
    case '768px': {
      return `${Math.round(size * 0.9)}px`;
    }
    case '1024px': {
      return `${Math.round(size * 0.95)}px`;
    }
    case '1280px': {
      return `${size}px`;
    }
    case '1536px': {
      return `${size}px`;
    }
    default: {
      return `${size}px`;
    }
  }
};

export const radius = {
  r0: '0px',
  r1: '4px',
  r2: '8px',
  r3: '12px',
  r4: '16px',
  r5: '20px',
  r6: '24px',
};

export const media = {
  $wrapper_min_width: '1280px',
  $wrapper_max_width: '1280px',
  $header_height: '104px',
  $child_header_height: '60px',
  $footer_height: '136px',
  $mobile_content_max_width: '360px',
  $sm: '640px',
  $md: '768px',
  $lg: '1024px',
  $xl: '1280px',
  $2xl: '1536px',
};

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  height: '100%',
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  borderRadius: 2,
};

export const modalMobileStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

export const termModalStyle = {
  height: 780,
};