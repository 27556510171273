import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TextInput, Button, Stepper, CheckBox, FileUploader } from '../../components';
import { useForm, useLabel } from '../../hooks';
import { IND_TERM_KO, IND_TERM_EN, INFO_TERM_KO, INFO_TERM_EN } from '../../constants/term';
import _ from 'lodash';
import Handler from '../../Handler';
import { LangContext, SnackbarContext, UserContext } from '../../contexts';
import API from '../../api';
import queryString from 'query-string';
import { t } from 'i18next';

export const AddInfoPage = (props) => {
  const history = useHistory();
  const { SIGN_UP_TERM, ERROR, MSG } = useLabel();
  const { isKorean } = useContext(LangContext);
  const searchObject = queryString.parse(history.location.search);
  const { updateSnackbar } = useContext(SnackbarContext);
  const [step, setStep] = useState(0);
  const [marketingAgree, setMarketingAgree] = useState(false);
  const { userInfo, updateAccessToken, refreshUserInfo } = useContext(UserContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      name: '',
      phone: '',
      profileImage: '',
    },
    {
      name: {
        key: 'name',
        label: t('NAME'),
        style: {},
        className: 'point',
        required: true,
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        style: {},
        className: 'point',
        type: 'tel',
        regexp: Handler.REGEXP.MOBILE,
        regexpMsg: ERROR.PHONE,
        maxlength: '11',
        required: true,
      },
      profileImage: {
        type: 'image',
        key: 'profileImage',
        label: t('PROFILE_IMAGE'),
        path: 'signup/profile_image',
        className: 'point',
        helperText: t('PROFILE_IMAGE_GUIDE'),
      },
    },
  );
  const [termsChecks, setTermsChecks] = useState([false, false, false]);
  const [termsAllCheck, setTermsAllCheck] = useState(false);

  useEffect(() => {
    if (userInfo) {
      let new_formData = _.clone(formData);
      if (userInfo.profilePath) {
        new_formData.profileImage = userInfo.profilePath;
      }
      setFormData(new_formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if ('isMarketingAgree' in searchObject) {
      setMarketingAgree(searchObject?.isMarketingAgree);
    }
  }, [searchObject]);

  useEffect(() => {
    if (termsChecks) {
      const is_all_true = termsChecks.every((e) => e);
      setTermsAllCheck(is_all_true);
      setMarketingAgree(is_all_true ? is_all_true : termsChecks[2]);
    }
  }, [termsChecks]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [step]);

  const onChangeStep = (new_value) => {
    setStep(new_value);
  };

  const onChangeTermsCheck = (new_value, index) => {
    let new_termsChecks = _.clone(termsChecks);
    new_termsChecks[index] = new_value;
    setTermsChecks(new_termsChecks);
  };

  const validationMessage = (TARGET_KEY) => {
    let message = '';
    if (['name', 'phone'].includes(TARGET_KEY[0])) {
      message = ERROR[TARGET_KEY[0].toUpperCase()];
    }
    updateSnackbar({
      type: 'error',
      open: true,
      message: message || ERROR['REQUIRED_INFO'],
    });
    return;
  };

  const requestSignup = () => {
    const PERSONAL_VALIDATION_LIST = ['name', 'phone'];
    let isValidation = Handler.formValidation(formData, PERSONAL_VALIDATION_LIST);
    if (!isValidation) {
      validationMessage(Handler.formToBodyKey(formData, PERSONAL_VALIDATION_LIST));
    } else {
      let db_body = {
        ...formData,
        isMarketingAgree: marketingAgree,
        profileImage: formData.profileImage || Handler.GET_DEFAULT_IMAGE('personal'),
      };
      API.User.PutSignupInfo(db_body)
        .then((res) => {
          updateSnackbar({
            type: 'success',
            open: true,
            message: MSG['SIGN_UP_SUCCESS'],
          });
          refreshUserInfo();
          history.push('/');
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header>
            <h1>{t('ADDITIONAL_INFO')}</h1>
          </header>
        </section>
        <section className='user-stepper__container'>
          <Stepper
            meta={{}}
            value={step}
            label={[t('TERM_AGREEMENT'), t('INPUT_DETAILS')]}
            onChange={onChangeStep}
          ></Stepper>
        </section>
        {step === 0 && (
          <section className='user-form__container'>
            <header>
              <h2>
                {t('MARKETPLACE')} {t('TERM_AGREEMENT')}
              </h2>
            </header>
            <ul className='terms__list'>
              <li>
                <header>
                  <CheckBox
                    value={termsChecks[0]}
                    onChange={(new_value) => {
                      onChangeTermsCheck(new_value, 0);
                    }}
                    meta={{
                      required: true,
                      label: SIGN_UP_TERM['PRIVACY'],
                      subLabel: `(*${t('REQUIRED')})`,
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
                <div className='text__viewer'>
                  <div className='online-terms__box'>
                    <div dangerouslySetInnerHTML={{ __html: isKorean ? INFO_TERM_KO.content : INFO_TERM_EN.content }} />
                  </div>
                </div>
              </li>
              <li>
                <header>
                  <CheckBox
                    value={termsChecks[1]}
                    onChange={(new_value) => {
                      onChangeTermsCheck(new_value, 1);
                    }}
                    meta={{
                      required: true,
                      label: SIGN_UP_TERM['CREDIT'],
                      subLabel: `(*${t('REQUIRED')})`,
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
                <div className='text__viewer'>
                  <div className='online-terms__box'>
                    <div dangerouslySetInnerHTML={{ __html: isKorean ? IND_TERM_KO.content : IND_TERM_EN.content }} />
                  </div>
                </div>
              </li>
              <li>
                <header>
                  <CheckBox
                    value={termsChecks[2]}
                    onChange={(new_value) => {
                      onChangeTermsCheck(new_value, 2);
                    }}
                    meta={{
                      label: SIGN_UP_TERM['MARKETING'],
                      subLabel: `(${t('OPTIONAL')})`,
                      className: 'terms',
                    }}
                  ></CheckBox>
                </header>
              </li>
            </ul>
            <section style={{ display: 'flex', marginTop: 32, marginBottom: 32, justifyContent: 'center' }}>
              <CheckBox
                value={termsAllCheck}
                onChange={(new_value) => {
                  if (new_value) {
                    setTermsChecks([true, true, true]);
                  } else {
                    setTermsChecks([false, false, false]);
                  }
                  setTermsAllCheck(new_value);
                }}
                meta={{
                  label: t('AGREE_ALL'),
                  className: 'terms',
                }}
              ></CheckBox>
            </section>
            <section className='button__container'>
              <Button
                style={{ width: '180px', marginRight: 24 }}
                onClick={() => {
                  history.push('/user/login');
                }}
                label={t('PREV')}
                type='secondary'
              ></Button>
              <Button
                style={{ width: '180px' }}
                onClick={() => {
                  console.log('termsChecks', termsChecks);
                  if (termsChecks[0] && termsChecks[1]) {
                    setStep(1);
                  } else {
                    console.log('termsChecks');
                    updateSnackbar({
                      type: 'error',
                      open: true,
                      message: ERROR['REQUIRED_TERM'],
                    });
                    return;
                  }
                  return;
                }}
                label={t('NEXT')}
              ></Button>
            </section>
          </section>
        )}
        {step === 1 && (
          <>
            <section className='user-form__container'>
              <header>
                <h2>{t('MYACCOUNT')}</h2>
                <em>
                  <div className='ico required'></div> {t('REQUIRED')}
                </em>
              </header>
              <ul className='form__list'>
                <FileUploader
                  value={formData.profileImage}
                  onChange={formChange}
                  meta={formMeta.profileImage}
                ></FileUploader>
                <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
                <TextInput
                  value={formData.phone}
                  onChange={(value, key) => {
                    const reg = new RegExp('^[0-9]{0,11}$');
                    const isValidation = reg.test(value);
                    if (isValidation) {
                      formChange(value, key);
                    }
                  }}
                  meta={formMeta.phone}
                ></TextInput>
              </ul>
            </section>
            <section className='button__container'>
              <Button
                style={{ width: '180px', marginRight: 24 }}
                onClick={() => {
                  setStep(0);
                }}
                label={t('PREV')}
                type='secondary'
              ></Button>
              <Button
                style={{ width: '180px' }}
                onClick={() => {
                  requestSignup();
                }}
                label={t('NEXT')}
              ></Button>
            </section>
          </>
        )}
      </article>
    </article>
  );
};
