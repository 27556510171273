export const ROUTE_PATH = [
  {
    type: 'sub',
    text: 'ABOUT_POPLE',
    child: [
      { text: 'POPLE_INTRO', path: '/about' },
      { text: 'IDENTITY', path: '/brand' },
      { text: 'BIZ_INTRO', path: '/biz' },
      // { text: '프로젝트 사례', path: '/project' },
      { text: 'NEWS', path: '/news/list' },
    ],
  },
  {
    type: 'sub',
    text: 'STANDARD',
    child: [
      { text: 'STANDARD', path: '/standard' },
      // { text: '방법론', path: '/methodology/list' },
      //   { text: '인증', path: '' },
      { text: 'DIGITAL_SOLUTION', path: '/solution' },
    ],
  },
  {
    type: 'sub',
    text: 'MARKETPLACE',
    child: [
      { text: 'MARKETPLACE_INTRO', path: '/marketplace/intro' },
      { text: 'BUY_CREDIT', path: '/marketplace/list' },
      { text: 'POPLE_CERTIFICATION', path: '/cert' },
    ],
  },
  {
    type: 'sub',
    text: 'CARBON_MARKET_TREND',
    child: [
      // { text: '업계뉴스', path: '/trends' },
      { text: 'CARBON_PRICE', path: '/price' },
      { text: 'REDUCE_CARBON_FOOTPRINT', path: '/campaign' },
    ],
  },
  // {
  //   type: 'sub',
  //   text: '아카이빙',
  //   child: [
  //     { text: '팝플 인증서', path: '/cert' },
  //     { text: '자료실', path: '/documents' },
  //   ],
  // },
];

export const genRightPath = (isKorean = false, isLogin = false) => {
  let csChild = [
    {
      text: 'CONTACT_US',
      path: '/cs/contact_us',
    },
  ];
  if (isKorean) {
    csChild.splice(
      0,
      0,
      {
        text: 'NOTICE',
        path: '/cs/notice',
      },
      {
        text: 'FAQ',
        path: '/cs/faq',
      },
    );
  }

  let rightPath = [
    {
      type: 'link',
      text: 'REGISTRY',
      path: 'https://www.pople.credit/',
    },
    {
      type: 'sub',
      text: 'CS_CENTER',
      child: csChild,
    },
  ];

  if (!isLogin) {
    rightPath.splice(1, 0, {
      type: 'sign',
      list: [
        { text: 'LOGIN', path: '/user/login' },
        { text: 'SIGN_UP', path: '/user/signup_guide' },
      ],
    });
  }
  return rightPath;
};

export const FOOTER_ROUTE_PATH = [
  {
    type: 'sub',
    text: 'ABOUT_POPLE',
    child: [
      { text: 'POPLE_INTRO', path: '/about' },
      { text: 'BIZ_INTRO', path: '/biz' },
      { text: 'STANDARD', path: '/standard' },
    ],
  },
  {
    type: 'sub',
    text: 'ACT_WITH_US',
    child: [
      { outer: true, text: 'REGISTRY', path: 'https://www.pople.credit/' },
      { text: 'BUY_CREDITS', path: '/marketplace/list' },
    ],
  },
  {
    type: 'outer',
    text: 'RECRUIT',
    path: 'https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/greenery1',
  },
];

export const FAMILY_SITE = 'FAMILY_SITE';
export const FAMILY_SITE_LIST = [
  {
    text: 'CREDIT_REGISTRY',
    url: 'https://pople.credit',
  },
  {
    text: 'GREENERY_WEBSITE',
    url: 'https://www.greenery.im/',
  },
];

export const genMypageRoutes = (isPersonal = true) => {
  const menu = [
    {
      text: 'PURCHASEMENT_LIST',
      path: '/mypage/purchase_list',
    },
    {
      text: 'MODIFY_MYINFO',
      path: '/mypage/modify',
    },
  ];
  if (!isPersonal) {
    menu.splice(2, 0, {
      text: 'SELL_LIST',
      path: '/mypage/sell_list',
    });
  }
  return menu;
};