import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, TextInput, MetaTag, Button, FileUploader, AddressLocation } from '../../components';
import { useForm, useLabel } from '../../hooks';
import _ from 'lodash';
import Handler from '../../Handler';
import { SnackbarContext, UserContext } from '../../contexts';
import API from '../../api';

export const MypageModifyPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { MSG } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { userInfo, isPersonal, refreshUserInfo } = useContext(UserContext);
  const [formData, formMeta, formChange, setFormData, setFormMeta, formReset] = useForm(
    {
      email: '',
      name: '',
      phone: '',
      companyName: '',
      ceoName: '',
      profilePath: '',
      businessNumber: '',
      businessFile: '',
      address: {
        daum: null,
        detail: '',
      },
      site: '',
      fax: '',
      account: {
        bank: '',
        account: '',
        name: '',
      },
    },
    {
      email: {
        key: 'email',
        label: t('EMAIL'),
        style: {},
        className: 'point',
        disabled: true,
      },
      name: {
        key: 'name',
        label: t('RESPONSIBILITY'),
        style: {},
        className: 'point',
        required: true,
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        style: {},
        className: 'point',
        required: true,
      },
      companyName: {
        key: 'companyName',
        label: t('COMPANY_NAME'),
        style: {},
        className: 'point',
        disabled: true,
      },
      ceoName: {
        key: 'ceoName',
        label: t('CEO_NAME'),
        style: {},
        className: 'point',
        disabled: true,
      },
      businessNumber: {
        key: 'businessNumber',
        label: t('BIZ_NUMBER'),
        style: {},
        className: 'point',
        disabled: true,
      },
      profilePath: {
        type: 'image',
        key: 'profilePath',
        label: t('PROFILE_IMAGE'),
        path: 'signup/profile_image',
        className: 'point',
        helperText: t('PROFILE_IMAGE_GUIDE'),
        disabled: false,
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: t('BIZ_CERT'),
        path: 'signup/business_file',
        className: 'point',
        disabled: true,
      },
      address: {
        required: true,
        className: 'point',
        key: 'address',
        label: t('ADDRESS'),
        type: 'mypage',
      },
      site: {
        key: 'site',
        label: t('HOMEPAGE'),
        style: {},
        className: 'point',
        required: false,
      },
      fax: {
        key: 'fax',
        label: t('FAX'),
        style: {},
        className: 'point',
        required: false,
      },
    },
  );
  const [metaObject, setMetaObject] = useState({
    title: '팝플',
    description: '팝플에서 우리의 행동들이 모여 탄소 중립 문화를 만듭니다.',
    keywords: '팝플, 탄소중립, 탄소크레딧, VRC',
    imgsrc: '',
    canonical: '',
    url: window.location.href,
  });
  useEffect(() => {
    setMetaObject({
      title: '마이페이지 - 회원정보수정',
      description: '회원정보수정',
      keywords: '팝플이란, 팝플',
      image: '',
      canonical: '',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (isPersonal) {
        setFormData({
          ...formData,
          email: userInfo.email,
          name: userInfo.name,
          phone: userInfo.phone,
          ceoName: userInfo.ceoName,
          companyName: userInfo.companyName,
          businessNumber: userInfo.businessNumber,
          profilePath: userInfo.profilePath,
          businessFile: userInfo.businessFile,
          address: userInfo.address,
          site: userInfo.site,
          fax: userInfo.fax,
          account: userInfo.account
            ? userInfo.account
            : {
                bank: '',
                account: '',
                name: '',
              },
        });
      } else {
        setFormData({
          ...formData,
          email: userInfo.email,
          name: userInfo.name,
          phone: userInfo.phone,
          ceoName: userInfo.ceoName,
          companyName: userInfo.companyName,
          businessNumber: userInfo.businessNumber,
          profilePath: userInfo.profilePath,
          businessFile: userInfo.businessFile,
          address: userInfo.address,
          site: userInfo.site,
          fax: userInfo.fax,
          account: userInfo.account
            ? userInfo.account
            : {
                bank: '',
                account: '',
                name: '',
              },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  console.log('formData', formData);

  const requestModify = () => {
    /* Validation */
    let isValidation = Handler.formValidation(formData, ['name', 'phone']);
    if (!isValidation) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    } else {
      /* 유효할 경우 */
      const payload = {
        name: formData.name,
        address: typeof formData.address !== 'string' ? JSON.stringify(formData.address) : formData.address,
        phone: formData.phone,
        site: formData.site,
        fax: formData.fax,
        account: {
          bank: formData.account?.bank,
          account: formData.account?.account,
          name: formData.account?.name,
        },
        profilePath: formData?.profilePath || Handler.GET_DEFAULT_IMAGE(userInfo.userType.toLowerCase()),
      };
      API.User.Put(payload)
        .then((res) => {
          let result = res.data;
          console.log('User Put result', result);
          refreshUserInfo();
          updateSnackbar({
            type: 'success',
            open: true,
            message: '회원정보 수정 성공',
          });
        })
        .catch((err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        });
    }
  };

  if (!userInfo) {
    return null;
  }

  if (isPersonal) {
    return (
      <>
        <MetaTag props={metaObject} />
        <article className='basic-page'>
          <article className='content__wrapper'>
            <section className='headding-text__container'>
              <header>
                <h1>{t('MODIFY_MYINFO')}</h1>
              </header>
              <div className='right__box'>
                <Breadcrumbs type={'small'}></Breadcrumbs>
              </div>
            </section>

            <section className='user-form__container'>
              <header>
                <h2>{t('MYACCOUNT')}</h2>
                <em>
                  <div className='ico required'></div> {t('REQUIRED')}
                </em>
              </header>
              <ul className='form__list'>
                <FileUploader
                  value={formData.profilePath}
                  onChange={formChange}
                  meta={formMeta.profilePath}
                ></FileUploader>
                <TextInput value={formData.email} onChange={formChange} meta={{ ...formMeta.email }}></TextInput>
                {userInfo.joinedType === 'Email' && (
                  <article className='form-custom point'>
                    <header>
                      <span>{t('PASSWORD')}</span>
                    </header>
                    <div className='button__box'>
                      <Button
                        style={{ width: '120px' }}
                        type='secondary-square'
                        onClick={() => {
                          history.push('/user/reset_pw');
                        }}
                        label={t('CHANGE_PASSWORD')}
                      ></Button>
                    </div>
                  </article>
                )}

                <TextInput
                  value={formData.name}
                  onChange={formChange}
                  meta={{
                    key: 'name',
                    label: t('NAME'),
                    style: {},
                    className: 'point',
                    required: true,
                  }}
                ></TextInput>
                <TextInput
                  value={formData.phone}
                  onChange={(value, key) => {
                    const reg = new RegExp('^[0-9]{0,11}$');
                    const isValidation = reg.test(value);
                    if (isValidation) {
                      formChange(value, key);
                    }
                  }}
                  meta={formMeta.phone}
                ></TextInput>
              </ul>
              <div className='sign-out-text'>
                <a href='/mypage/signout'>{t('RESIGN_MEMBERSHIP')}</a>
              </div>
            </section>
            <section className='user-form__container'>
              <header>
                <h2>{t('ACCOUNT')}</h2>
              </header>
              <div style={{ marginTop: 12 }}>
                <span style={{ fontSize: 12, color: '#777' }}>{MSG['PURCHASE_ACCOUNT_GUIDE']}</span>
              </div>
              <ul className='form__list'>
                <TextInput
                  value={formData.account.bank}
                  onChange={(value) => {
                    console.log('value', value);
                    let new_account = _.clone(formData);
                    new_account.account.bank = value;
                    setFormData(new_account);
                  }}
                  meta={{
                    key: 'bank',
                    label: t('BANK_NAME'),
                    style: {},
                    required: false,
                  }}
                ></TextInput>
                <TextInput
                  value={formData.account.account}
                  onChange={(value) => {
                    console.log('value', value);
                    let new_account = _.clone(formData);
                    new_account.account.account = value;
                    setFormData(new_account);
                  }}
                  meta={{
                    key: 'account',
                    label: t('BANK_ACCOUNT_NUMBER'),
                    placeholder: t('BANK_ACCOUNT_NUMBER_PLACEHOLDER'),
                    style: {},
                    required: false,
                  }}
                ></TextInput>
                <TextInput
                  value={formData.account.name}
                  onChange={(value) => {
                    console.log('value', value);
                    let new_account = _.clone(formData);
                    new_account.account.name = value;
                    setFormData(new_account);
                  }}
                  meta={{
                    key: 'name',
                    label: t('BANK_ACCOUNT_USER'),
                    style: {},
                    required: false,
                  }}
                ></TextInput>
              </ul>
            </section>
            <section className='button__container' style={{ maxWidth: 560 }}>
              <Button
                style={{ width: '180px' }}
                onClick={() => {
                  requestModify();
                }}
                label={t('MODIFY')}
              ></Button>
            </section>
          </article>
        </article>
      </>
    );
  } else {
    return (
      <>
        <MetaTag props={metaObject} />
        <article className='basic-page'>
          <article className='content__wrapper'>
            <section className='headding-text__container'>
              <header>
                <h1>{t('MODIFY_MYINFO')}</h1>
              </header>
              <div className='right__box'>
                <Breadcrumbs type={'small'}></Breadcrumbs>
              </div>
            </section>

            <section className='user-form__container'>
              <header>
                <h2>{t('MYACCOUNT')}</h2>
                <em>
                  <div className='ico required'></div> {t('REQUIRED')}
                </em>
              </header>
              <ul className='form__list'>
                <TextInput value={formData.email} onChange={formChange} meta={formMeta.email}></TextInput>
                <article className='form-custom point'>
                  <header>
                    <span>{t('PASSWORD')}</span>
                  </header>
                  <div className='button__box'>
                    <Button
                      style={{ width: '120px' }}
                      type='secondary-square'
                      onClick={() => {
                        history.push('/user/reset_pw');
                      }}
                      label={t('CHANGE_PASSWORD')}
                    ></Button>
                  </div>
                </article>

                <TextInput value={formData.name} onChange={formChange} meta={formMeta.name}></TextInput>
                <TextInput
                  value={formData.phone}
                  onChange={(value, key) => {
                    const reg = new RegExp('^[0-9]{0,11}$');
                    const isValidation = reg.test(value);
                    if (isValidation) {
                      formChange(value, key);
                    }
                  }}
                  meta={formMeta.phone}
                ></TextInput>
              </ul>
              <div className='sign-out-text'>
                <a href='/mypage/signout'>{t('RESIGN_MEMBERSHIP')}</a>
              </div>
            </section>
            <section className='user-form__container'>
              <header>
                <h2>{t('COMPANY_PROFILE')}</h2>
                <em>
                  <div className='ico required'></div> {t('REQUIRED')}
                </em>
              </header>
              <ul className='form__list'>
                <TextInput value={formData.companyName} onChange={formChange} meta={formMeta.companyName}></TextInput>
                <TextInput value={formData.ceoName} onChange={formChange} meta={formMeta.ceoName}></TextInput>
                <TextInput
                  value={formData.businessNumber}
                  onChange={formChange}
                  meta={formMeta.businessNumber}
                ></TextInput>
                <FileUploader
                  value={formData.profilePath}
                  onChange={formChange}
                  meta={formMeta.profilePath}
                ></FileUploader>
                <FileUploader
                  value={formData.businessFile}
                  onChange={formChange}
                  meta={formMeta.businessFile}
                ></FileUploader>
                <AddressLocation
                  value={formData.address}
                  onChange={formChange}
                  meta={formMeta.address}
                ></AddressLocation>
                <TextInput value={formData.site} onChange={formChange} meta={formMeta.site}></TextInput>
                <TextInput
                  value={formData.fax}
                  onChange={(value, key) => {
                    const reg = new RegExp('^[0-9]{0,11}$');
                    const isValidation = reg.test(value);
                    if (isValidation) {
                      formChange(value, key);
                    }
                  }}
                  meta={formMeta.fax}
                ></TextInput>
              </ul>
            </section>
            <section className='user-form__container'>
              <header>
                <h2>{t('BANK_ACCOUNT')}</h2>
              </header>
              <div style={{ marginTop: 12 }}>
                <span style={{ fontSize: 12, color: '#777' }}>{t('BANK_ACCOUNT_GUIDE')}</span>
              </div>
              <ul className='form__list'>
                <TextInput
                  value={formData.account.bank}
                  onChange={(value) => {
                    console.log('value', value);
                    let new_account = _.clone(formData);
                    new_account.account.bank = value;
                    setFormData(new_account);
                  }}
                  meta={{
                    key: 'bank',
                    label: t('BANK_NAME'),
                    style: {},
                    required: false,
                  }}
                ></TextInput>
                <TextInput
                  value={formData.account.account}
                  onChange={(value) => {
                    console.log('value', value);
                    let new_account = _.clone(formData);
                    new_account.account.account = value;
                    setFormData(new_account);
                  }}
                  meta={{
                    key: 'account',
                    label: t('BANK_ACCOUNT_NUMBER'),
                    placeholder: t('BANK_ACCOUNT_NUMBER_PLACEHOLDER'),
                    style: {},
                    required: false,
                  }}
                ></TextInput>
                <TextInput
                  value={formData.account.name}
                  onChange={(value) => {
                    console.log('value', value);
                    let new_account = _.clone(formData);
                    new_account.account.name = value;
                    setFormData(new_account);
                  }}
                  meta={{
                    key: 'name',
                    label: t('BANK_ACCOUNT_USER'),
                    style: {},
                    required: false,
                  }}
                ></TextInput>
              </ul>
            </section>
            <section className='button__container' style={{ maxWidth: 560 }}>
              <Button
                style={{ width: '180px' }}
                onClick={() => {
                  requestModify();
                }}
                label={t('MODIFY')}
              ></Button>
            </section>
          </article>
        </article>
      </>
    );
  }
};
