import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import IsoCert from '../../styles/icon/iso_cert.svg';
import IETA from '../../styles/icon/logo_ieta.svg';
import { ContentContext, LangContext } from '../../contexts';
import {IND_TERM_KO, IND_TERM_EN, COMPANY_TERM_KO, COMPANY_TERM_EN, INFO_TERM_KO, INFO_TERM_EN } from '../../constants/term'
import { TermService } from './components';
import { media } from '../../styles/style';
import {
  FOOTER_ROUTE_PATH,
  FAMILY_SITE_LIST,
} from '../../constants';
import Handler from '../../Handler';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

const m_style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};

const FooterNavContainer = styled.section`
  max-width: calc(1/2);
  width: 100%;
  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    cursor: pointer;
    span {
      font-size: 18px;
      font-weight: 700;
    }
    @media only screen and (max-width: ${media.$sm}) {
      grid-template-columns: repeat(2, 1fr);
      li {
        margin-bottom: 20px;
      }
    }
  }
  .company-info {
    padding: 60px 0 40px;
    div {
      margin-bottom: 10px;
      span {
        font-size: 14px;
        line-height: 18px;
        color: #555;
        a {
          text-decoration: underline;
        }
      }
    }
    .divider {
      display: block;
      margin: 0 0.3rem;
      @media only screen and (max-width: ${media.$sm}) {
        display: none;
        margin: 0 0.2rem;
      } 
    }
  }
`;

const FooterNavItem = styled.li`
  ul.item-child {
    display: block;
    margin-top: 20px;
    li {
      margin: 10px 0;
      cursor: pointer;
    }
  }
`;

export const MainFooter = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const { deviceWidth } = useContext(ContentContext);
  const { isKorean } = useContext(LangContext);
  const [term, setTerm] = useState('');
  
  const NAV_LIST = FOOTER_ROUTE_PATH;
  const SITE_LIST = FAMILY_SITE_LIST;

  const openModal = (value) => {
    if (value === 'term_person') setOpen(true);
    if (value === 'term_company') setOpen2(true);
    if (value === 'term_info') setOpen3(true);
  };

  useEffect(() => {
    if (open) isKorean ? setTerm(IND_TERM_KO) : setTerm(IND_TERM_EN)
    if (open2) isKorean ? setTerm(COMPANY_TERM_KO) : setTerm(COMPANY_TERM_EN)
    if (open3) isKorean ? setTerm(INFO_TERM_KO) : setTerm(INFO_TERM_EN)

  }, [open, open2, open3])

  return (
    <>
      <article className='main-footer m-auto'>
        <FooterNavContainer>
          <ul className=''>
            {NAV_LIST.map((item, index) => {
              return (
                <FooterNavItem key={'navlist' + index}>
                  {item.type === 'outer' ? (
                    <span onClick={() => Handler.gotoOuterLink(item.path)}>{t(item.text)}</span>
                  ) : (
                    <span>{t(item.text)}</span>
                  )}
                  <ul className='item-child'>
                    {item.child &&
                      item.child.map((li) => {
                        if (item.text === 'TERM') {
                          return <li key={`list+${li.text}`} onClick={() => openModal(li.value)}>{t(li.text)}</li>;
                        }
                        if (li.outer) {
                          return <li key={`list+${li.text}`} onClick={() => Handler.gotoOuterLink(li.path)}>{t(li.text)}</li>;
                        }
                        return (
                          <li key={`list+${li.text}`}>
                            <Link to={li.path}>{t(li.text)}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </FooterNavItem>
              );
            })}
          </ul>
        </FooterNavContainer>
      </article>
      <article className='main-footer m-auto'>
          <section className='left__container py-6'>
            <div className='caption__box'>
              <span className='mb-3 font-bold'>{`${t('GREENERY')}`}</span>
              <div>
                <span>{t('BIZ_NUMBER')}: 401-88-02247</span>
                <div className='mx-2 border-gray-400 border-l border-solid m-1'></div>
                <span>{`${t('CEO')}: ${t('YSH')}`}</span>
                <div className='mx-2 border-gray-400 border-l border-solid m-1'></div>
                <span>{t('EMAIL')}: info@greenery.im</span>
              </div>
              <div>
                <span>
                  {t('BIZ_SELLER')}: {t('BIZ_SELLER_NUMBER')}
                </span>
                <div className='mx-2 border-gray-400 border-l border-solid m-1'></div>
                <span>{`${t('ADDRESS')}: ${t('COMPANY_ADDRESS')}`}</span>
              </div>
            </div>
          </section>
          <section className='right__container'>
            <select
              value='none'
              onChange={(e) => {
                window.open(e.target.value, '_blank');
              }}
            >
              <option value='none'>{t('FAMILY_SITE')}</option>
              {SITE_LIST.map((item, index) => {
                return (
                  <option key={item.text + index} value={item.url}>
                    {t(item.text)}
                  </option>
                );
              })}
            </select>
          </section>
        </article>
        <article className='w-full flex flex-col justify-center bg-slate-50'>
          <article className='main-footer m-auto'>
            <section className='w-full flex justify-between'>
              <div className='flex gap2 items-center'>
                <div className='mr-4'>
                  <img src={IETA} alt='' />
                </div>
                <div className='mr-4'>
                  <img src={IsoCert} alt='' />
                </div>
                <div>
                  <p className='text-sm text-gray-400'>{`${t('SCOPE')}: ${t('SCOPE_TITLE')}`}</p>
                  <p className='text-sm text-gray-400 mt-2'>{`${t('EXPIRE_DATE')}: ${t('EXPIRE_DATE_STR')}`}</p>
                </div>
              </div>
              <div>
                <div className='flex gap-2 justify-end'>
                  <span className='cursor-pointer text-gray-400 text-sm' onClick={() => openModal('term_person')}>
                    {t('TERM_PERSONAL')}
                  </span>
                  <div className='border-gray-400 border-l border-solid m-1'></div>
                  <span className='cursor-pointer text-gray-400 text-sm' onClick={() => openModal('term_company')}>
                    {t('TERM_COMPANY')}
                  </span>
                  <div className='border-gray-400 border-l border-solid m-1'></div>
                  <span className='cursor-pointer text-gray-400 text-sm' onClick={() => openModal('term_info')}>
                    {t('TERM_PRIVACY')}
                  </span>
                </div>
                <div className='mt-2'>
                  <span className='text-sm text-gray-400'>ⓒ Greenery Inc. ALL RIGHTS RESERVED.</span>
                </div>
              </div>
            </section>
          </article>
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <Box sx={deviceWidth > 1136 ? style : m_style}>
              <TermService
                title={term.title}
                term={term.content}
                onClose={() => {
                  setOpen(false);
                }}
              />
            </Box>
          </Modal>
          <Modal
            open={open2}
            onClose={() => {
              setOpen2(false);
            }}
          >
            <Box sx={deviceWidth > 1136 ? style : m_style}>
              <TermService
                title={term.title}
                term={term.content}
                onClose={() => {
                  setOpen2(false);
                }}
              />
            </Box>
          </Modal>
          <Modal
            open={open3}
            onClose={() => {
              setOpen3(false);
            }}
          >
            <Box sx={deviceWidth > 1136 ? style : m_style}>
              <TermService
                title={term.title}
                term={term.content}
                onClose={() => {
                  setOpen3(false);
                }}
              />
            </Box>
          </Modal>
        </article>
    </>
  );
};
